import React from "react";
import Home from "./pages/Home";
import { Routes, Route } from "react-router-dom";
import Aboutus from "./components/AboutUs/aboutus";
import "./App.css";
import Box from "@mui/material/Box";
import Products from "./components/Products/products";

function App() {
  return (
    <Box className="App backgroundImage">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="products" element={<Products />} />

        <Route path="about" element={<Aboutus />} />
      </Routes>
    </Box>
  );
}

export default App;
