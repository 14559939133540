import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";

const LandingPage = () => {
  const isMobile = useMediaQuery("(max-width:1024px)");
  return (
    <>
      <Box sx={{ justifyContent: "center", mt: isMobile ? 0 : 10 }}>
        <Typography
          variant="h6"
          sx={{ display: "block", fontFamily: "monospace" }}
        >
          Welcome to Steed Medical company, based in the UAE. We proudly offer a
          wide range of health solutions from renowned Italian and European
          brands, catering to women, children, and dental care. Our products are
          designed to enhance well-being and meet the diverse needs of customers
          across the GCC region. At the forefront of innovation, we prioritize
          safety and efficacy.
        </Typography>
        <Typography
          variant="h6"
          sx={{ display: "block", fontFamily: "monospace" }}
        >
          Our commitment to research and development ensures effective solutions
          tailored to your health needs. Explore our diverse offerings and
          experience excellence in healthcare. Together, we strive to promote
          healthier lives and empower communities with trusted products. Thank
          you for choosing us!
        </Typography>
      </Box>
    </>
  );
};

export default LandingPage;
