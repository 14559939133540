import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const SimpleProductCard = ({ title, src, description }) => {
  return (
    <Card elevation={2} sx={{ backgroundColor: "#909090" }}>
      <CardHeader
        sx={{ py: 1 }}
        title={title}
        titleTypographyProps={{ fontFamily: "monospace", p: 1 }}
      />
      <CardMedia
        sx={{ objectFit: "fill", height: 300 }}
        component="img"
        src={src}
        alt="Product one"
      />
      <CardContent sx={{ backgroundColor: "#909090", py: 1 }}>
        <Typography variant="body2" fontFamily="monospace" sx={{ px: 0.5 }}>
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SimpleProductCard;
