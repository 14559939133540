import React from "react";
import { Link } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

export default function Aboutus() {
  const isMobile = useMediaQuery("(max-width:1024px)");
  return (
    <Box
      sx={{
        width: "100%",
        height: "42vh",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
      }}
    >
      <Grid container>
        {/* First Row */}
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            <Box
              sx={{
                display: isMobile ? "block" : "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#40E0D0", fontFamily: "monospace" }}
              >
                Copy@2025 Steed Medical Store Company. All rights reserved.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#40E0D0",
                  fontFamily: "monospace",
                }}
              >
                "Committed to enhancing healthcare and well-being for all."
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{ color: "#40E0D0", fontFamily: "monospace" }}
              >
                Address: Al Bees 6 St, Mussafah M14, Abu Dhabi, UAE. Operating
                Hours: Mon-Fri, 8:00 AM - 5:00 PM
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Second Row */}
        {/* FIRST COLUMNS Image */}
        <Grid
          item
          xs={4}
          sm={5}
          lg={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              mt: 4,
              width: isMobile ? "150px" : "200px",
              height: isMobile ? "100px" : "180px",
            }}
            component="img"
            src="../../../assets/DMPC512.png"
            alt="logo"
          />
        </Grid>

        {/* SECOND COLUMNS Image */}
        <Grid
          item
          xs={4}
          sm={5}
          lg={4}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box
            sx={{
              width: isMobile ? "150px" : "350px",
              height: isMobile ? "150px" : "250px",
            }}
            component="img"
            src="../../../assets/logo.png"
            alt="logo"
          />
        </Grid>
        {/* THIRD COLUMNS Image */}
        <Grid
          container
          item
          xs={8}
          sm={7}
          lg={4}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PhoneIcon
                sx={{
                  fontSize: isMobile ? "2rem" : "2.2rem",
                  color: "#40E0D0",
                  marginLeft: isMobile ? 1 : 4,
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "#40E0D0",
                  fontFamily: "monospace",
                  ml: 1,
                }}
              >
                +971556144907
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginY: isMobile ? 1.5 : 2.5,
              }}
            >
              <LinkedInIcon
                sx={{
                  fontSize: isMobile ? "2rem" : "2.2rem",
                  color: "#40E0D0",
                  marginLeft: isMobile ? 1 : 4,
                }}
              />
              <Link
                href="https://www.linkedin.com/company/steed-pharmaceuticals-trading/"
                underline="none"
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#40E0D0",
                    fontFamily: "monospace",
                    ml: 1,
                  }}
                >
                  Steed Medical Store L.L.C
                </Typography>
              </Link>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginY: isMobile ? 1.5 : 2.5,
              }}
            >
              <EmailIcon
                sx={{
                  fontSize: isMobile ? "2rem" : "2.2rem",
                  color: "#40E0D0",
                  marginLeft: isMobile ? 1 : 4,
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  color: "#40E0D0",
                  fontFamily: "monospace",
                  ml: 1,
                }}
              >
                kais.chahem@steedpharma.com
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationOnIcon
                sx={{
                  fontSize: isMobile ? "2rem" : "2.2rem",
                  color: "#40E0D0",
                  marginLeft: isMobile ? 1 : 4,
                }}
              />
              <Link
                href="https://maps.app.goo.gl/WDRXa9aVV28548jK7"
                underline="none"
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#40E0D0",
                    fontFamily: "monospace",
                    ml: 1,
                  }}
                >
                  Al Bees 6 St, Abu Dhabi, UAE
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
