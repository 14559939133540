import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Aboutus from "../components/AboutUs/aboutus";
import LandingPage from "../components/LandingPage/landingPage";
import Header from "../components/Header/header";
import Products from "../components/Products/products";

const Home = () => {
  return (
    <Box>
      <Header />
      <Container
        sx={{
          paddingTop: 12,
          maxWidth: {
            xs: "100%", // Full width on extra small screens
            sm: "95%", // 95% width on small screens
            md: "90%", // 90% width on medium screens
            lg: "85%", // 85% width on large screens
          },
        }}
      >
        <Box
          alignItems={"inherit"}
          justifyContent={"center"}
          sx={{ width: "100%", minHeight: "55vh" }}
        >
          <LandingPage />
        </Box>
      </Container>
      <Products />
      <Box
        id={"aboutus"}
        display={"flex"}
        alignItems={"inherit"}
        justifyContent={"center"}
      >
        <Aboutus />
      </Box>
    </Box>
  );
};

export default Home;
