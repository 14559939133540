import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";

const Header = () => {
  const isMobile = useMediaQuery("(max-width:1024px)");

  return (
    <React.Fragment>
      {isMobile ? <MobileHeader /> : <DesktopHeader />}
    </React.Fragment>
  );
};

export default Header;
